import { cateringRequest, cateringRequestHide, cateringUploadsHide } from '@/utils/fetch'
import { uploadFileItem } from './base'

interface saveReq {
  project_id: number // 项目ID
  project_name: string // 项目名称
  type: number // 1普通回款 2质保金
  money: string // 回款金额
  explain?: string // 回款说明
  pics?: Array<uploadFileItem> // 图片 // 回款图片证明
  date: string // 回款日期
}
// 项目回款 添加接口
export function saveProjectPayment(data: saveReq) {
  const url = '/api/project_payment/save'
  return cateringRequest.post(url, data)
}
